import React from 'react';
import "../HomeService/Service.css";
import TeethCleaning from "../../Images/Home/Teeth Cleaning.png";
import DentalFillings from "../../Images/Home/Dental Fillings.png";
import ToothExtraction from "../../Images/Home/Tooth Extraction.png";
import TeethWhitening from "../../Images/Home/Teeth Whitening.png";
import FamilyDentistry from "../../Images/Home/Family Dentistry.png";
import CosmeticDentistry from "../../Images/Home/Cosmetic Dentistry.png";

const Service = () => {
    return (
        <>
            <section className="service-section">
                <div className="container-fullwidth">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="offers-content">
                                <div className="section-title left">
                                    <h2 className='Head-Style-Service'>
                                        Services <span>We Offers</span>
                                    </h2>
                                </div>
                                <div className="text">
                                    <p>
                                    Welcome to 32 Dental Square, where we offer a comprehensive range of dental services to cater to all your oral health needs. Our expert team of dentists is committed to providing personalized care and cutting-edge treatments to ensure your smile remains healthy and beautiful. From routine check-ups and cleanings to advanced procedures such as dental implants, orthodontics, and cosmetic dentistry, we have the expertise to address any dental concern you may have.
                                    </p>
                                    <p>
                                    We prioritize patient comfort and satisfaction, employing state-of-the-art technology and techniques to deliver outstanding results.
                                    </p>
                                </div>
                                {/* <div className="button">
                                    <a>
                                    <button>
                                        view details
                                    </button>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="icon-area centered">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={TeethCleaning} alt='Teeth Cleaning Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Teeth Cleaning</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={DentalFillings} alt='Teeth Fillings Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Dental Fillings</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={ToothExtraction} alt='Tooth Extraction Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Tooth Extraction</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={TeethWhitening} alt='Teeth Whitening Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Teeth Whitening</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={FamilyDentistry} alt='Family Dentistry Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Family Dentistry</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                                        <div className="single-item">
                                            <div className="icon-box">
                                                <div className='Services-Image' href="service-details.html">
                                                    <img src={CosmeticDentistry} alt='Cosmetic Dentistry Clinic In Dadar West' />
                                                </div>
                                            </div>
                                            <h5>
                                                <a href="service-details.html">Cosmetic Dentistry</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Service