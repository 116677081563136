import React, { useState, useLayoutEffect } from "react";
import "../Css/Gallery.css";
import GalleryData from "../Component/Gallery/GalleryData";
import Model from "../Component/Gallery/Model";
import Zoom from "react-reveal/Zoom";
import { MetaTags } from "react-meta-tags";
import Bounce from "react-reveal/Bounce";
import Footer from "../Component/Footer/Footer";

const Gallery = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [clickImage, setClickImage] = useState(null);
    const [cureentIndex, setCureentIndex] = useState(null);

    const handelClick = (item, index) => {
        setCureentIndex(index);
        setClickImage(item.gImage);
    };

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].gImage;
            setClickImage(newurl);
            return;
        }
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].gImage;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    return (
        <>
            <MetaTags>
                <title>Best Dentists In Dadar West | 32 Dental Square</title>
                <meta title="Best Dentists In Dadar West | 32 Dental Square" />
                <meta
                    name="description"
                    content="Experience exceptional dental care at 32 Dental Square, home to the best dentists in Dadar West. Your smile deserves the finest!"
                />
                <meta
                    name="keywords"
                    content="Best Dental Clinic In Dadar West,Dental Clinic In Dadar,Dental Clinic In Mumbai,Dentist In Dadar West,Cosmetic Dentistry Doctor In Dadar West,Dental Implant In Dadar West,Best Orthodontist In Dadar West,Pediatric Dentistry In Dadar West,Top Dentistry Dentist in Mumbai,Best Preventive Dentistry In Dadar,Digital Dentistry In Dadar,Best Dentist In Dadar West,Dentists Near Dadar"
                />
                <link rel="canonical" href="https://32dentalsquare.in/best-dentists-dadar-west" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Dentists In Dadar West | 32 Dental Square" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://32dentalsquare.in/best-dentists-dadar-west" />
                <meta
                    property="og:description"
                    content="Experience exceptional dental care at 32 Dental Square, home to the best dentists in Dadar West. Your smile deserves the finest!"
                />
                <meta
                    property="og:image"
                    content="https://32dentalsquare.in/"
                />
            </MetaTags>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className="ProductList-Section-1">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <h1>Best Dentist In Dadar West</h1>
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Gallery</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className="Image-Gallery">
                <div className="container">
                    <div className="Image-Gallery-title">
                        <h4>Image gallery</h4>
                        <div className="gallery-contnet-para">
                            <p>Discover the exceptional work of our skilled team at 32 Dental Square. From stunning smile transformations to advanced dental procedures, our gallery showcases the quality and care we provide. Witness the difference firsthand and envision your own smile transformation. At 32 Dental Square, we're committed to delivering the best dental care in Dadar West. Experience the expertise of the best dentist in Dadar West and see why our patients trust us for all their dental needs.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {GalleryData.map((item, index) => {
                            return (
                                <>
                                    <div className="col-md-4">
                                        <Zoom>
                                            <div className="G-Images" key={index}>
                                                <img
                                                    src={item.gImage}
                                                    alt={item.alt}
                                                    onClick={() => handelClick(item, index)}
                                                />
                                            </div>
                                        </Zoom>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </section>
            {clickImage && (
                <Model
                    clickImage={clickImage}
                    handelRotationRight={handelRotationRight}
                    setClickImage={setClickImage}
                    handelRotationLeft={handelRotationLeft}
                />
            )}

            <Footer />
        </>
    );
};

export default Gallery;
