import profile from "../../Images/Testimonial/demo profile.png";

const TestimonialData = [
    {
        TestimonialName: "Rishabh Bhargava",
        TestimonialImage: profile,
        TestimonialDes: "Great experience, had multiple problems which Dr. Pooja took care of. She makes the treatment as comfy as possible and keeps the whole thing simple. Highly recommended. Also, pricing is one of the best around and parking is available too!",
    },
    {
        TestimonialName: "Shivraj Salunke",
        TestimonialImage: profile,
        TestimonialDes: "Hi,I am shivrajsalunke and I have done my dental teeth surgery ,under the guide lines ofDr Pooja Mehta at her clinic in Dadar.she is supur excellent in her filed and because thank to her.Today I have healthy and beautiful teeth smile.",
    },
    {
        TestimonialName: "Darshan Sancheti",
        TestimonialImage: profile,
        TestimonialDes: "Best dental in dadar, Well experienced, soft spoken and  treatment was done very professionally",
    },
    {
        TestimonialName: "RUPAL JAIN",
        TestimonialImage: profile,
        TestimonialDes: "Really had a best experience wid doctor Pooja  Mehta. Just loved her work !!! All the vry best Doctor.",
    },
    {
        TestimonialName: "Savithri Shyamrao",
        TestimonialImage: profile,
        TestimonialDes: "Best dentist in Dadar west.... Good quality and affordable treatment offered.",
    },
    {
        TestimonialName: "AMIT ABHYANKAR",
        TestimonialImage: profile,
        TestimonialDes: "Very soft spoken doctor,Well in explaining and reassuring.,Efficiently handing the procedure",
    },
    {
        TestimonialName: "Nidhi lodaya",
        TestimonialImage: profile,
        TestimonialDes: "Best.. Dr. Very polite in speaking & good work is being done",
    },
    {
        TestimonialName: "cdr. Sharma",
        TestimonialImage: profile,
        TestimonialDes: "I got my root canal done on 2 of my teeth by Dr Pooja Mehta recently,and I am totally satisfied by her treatment.She advised me very nicely about d line of treatment n treated me only as required avoiding unnecessary treatment,She has done a good job with reasonable charges.",
    },
    {
        TestimonialName: "Dayanand Joshilkar",
        TestimonialImage: profile,
        TestimonialDes: "Good experience ..Friendly ,well equipped helpful & crucial dental treatment too !!",
    },
    {
        TestimonialName: "Isha Jagawat",
        TestimonialImage: profile,
        TestimonialDes: "The treatment was very well done. The whole procedure was very less painful.",
    },
]

export default TestimonialData;
