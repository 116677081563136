import React, { useLayoutEffect } from 'react';
import "../Css/About.css";
import HomeAbout from '../Component/About/HomeAbout';
import OurAchivements from '../Component/Achivements/OurAchivements';
import OurVisionMission from '../Component/Mission/OurVisionMission';
import HomeTeam from '../Component/Team/HomeTeam';
import Footer from '../Component/Footer/Footer';
import { MetaTags } from 'react-meta-tags';
import bannerimage from "../Images/Banner/LIFELINE HOSPITAL 3.png"

const About = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <MetaTags>
                <title>Dental Clinic In Dadar | 32 Dental Square</title>
                <meta title="Dental Clinic In Dadar | 32 Dental Square" />
                <meta
                    name="description"
                    content="Visit 32 Dental Square, a premier dental clinic in Dadar, offering comprehensive dental care with expertise and compassion."
                />
                <meta
                    name="keywords"
                    content="Best Dental Clinic In Dadar West,Dental Clinic In Dadar,Dental Clinic In Mumbai,Dentist In Dadar West,Cosmetic Dentistry Doctor In Dadar West,Dental Implant In Dadar West,Best Orthodontist In Dadar West,Pediatric Dentistry In Dadar West,Top Dentistry Dentist in Mumbai,Best Preventive Dentistry In Dadar,Digital Dentistry In Dadar,Best Dentist In Dadar West,Dentists Near Dadar"
                />
                <link rel="canonical" href="https://32dentalsquare.in/dental-clinic-dadar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Dental Clinic In Dadar | 32 Dental Square" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://32dentalsquare.in/dental-clinic-dadar" />
                <meta
                    property="og:description"
                    content="Visit 32 Dental Square, a premier dental clinic in Dadar, offering comprehensive dental care with expertise and compassion."
                />
                <meta
                    property="og:image"
                    content="https://32dentalsquare.in/"
                />
            </MetaTags>
            <section className="ProductList-Section-1">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <h1>Dental Clinic In Dadar</h1>
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HomeAbout />
            {/* <OurAchivements /> */}
            <OurVisionMission />
            <HomeTeam />
            <Footer />
        </>
    )
}

export default About