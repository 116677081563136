import React from 'react';
import "../HomeDentalcare/Dentalcare.css";
import Dentalcare1 from "../../Images/Home/Dentalcare 1.avif";
import Dentalcare2 from "../../Images/Home/Dentalcare 2.avif";

import QualityDoctors from "../../Images/Home/Family Dentistry.png";
import FreeCheckup from "../../Images/Home/Free Checkup.png";
import AffordableClinic from "../../Images/Home/Affordable Clinic.png";
import LatestTechnology from "../../Images/Home/Latest Technology.png";

const Dentalcare = () => {
    return (
        <>
            <section className="about-us-area">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className='Head-Style'>About Dentalcare</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="left-content">
                                <div className="row">
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <img src={Dentalcare1} alt="Best Dental Clinic" />
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <img src={Dentalcare2} alt="Dental Clinic In Dadar West" />
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            <p>
                                                At 32 Dental Square, the best dental clinic in Dadar West, we offer top-quality dental care with a focus on patient satisfaction. Our experienced team provides a full range of services, including preventive care, cosmetic dentistry, and advanced treatments. Trust us for your routine check-ups, teeth whitening, and complex procedures. Visit 32 Dental Square to experience the best dental care in Dadar West.</p>
                                            <p className="mar-top">
                                            Whether you're due for a routine check-up or seeking transformative smile enhancements, trust us to deliver personalized care tailored to your unique needs. Experience the difference at 32 Dental Square, the best dental clinic in Dadar West, where your smile and oral health are our top priorities. Visit us to see why we're recognized as the best dental clinic in Dadar West for comprehensive and compassionate dental care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="right-content">
                                <div className="row">
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <div className="icon-holder">
                                                <div className="flaticon-medical-6">
                                                    <img src={QualityDoctors} alt='Dental Clinic In Dadar' />
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Experienced Team</h3>
                                                <p>Our skilled dentists ensure personalized care and outstanding results.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <div className="icon-holder">
                                                <div className="flaticon-medical-6">
                                                    <img src={FreeCheckup} alt='Dental Clinic' />
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Comprehensive Services</h3>
                                                <p>From routine check-ups to advanced treatments, we cover it all.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                </div>
                                <div className="row">
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <div className="icon-holder">
                                                <div className="flaticon-medical-6">
                                                    <img src={AffordableClinic} alt='Dentists Near Dadar' />
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Patient-Centered Approach</h3>
                                                <p>Your satisfaction and well-being are our top priorities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                    {/*Start single item*/}
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-item">
                                            <div className="icon-holder">
                                                <div className="flaticon-medical-6">
                                                    <img src={LatestTechnology} alt='Dentists Near Dadar West' />
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Cutting-Edge Technology</h3>
                                                <p>We utilize the latest techniques for optimal comfort and efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End single item*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dentalcare