
import Image01 from "../../Images/About/mission.avif";
import Image02 from "../../Images/About/vision.avif";
import Image03 from "../../Images/Details/Service Details a.avif";
import Image04 from "../../Images/Details/Service Details.avif";
import Image05 from "../../Images/Home/Dentalcare 1.avif";
import Image06 from "../../Images/Home/Dentalcare 2.avif";

const GalleryData = [
  {
    id: 1,
    gImage: Image01,
    alt:"Best Dentist In Dadar West",
  },
  {
    id: 2,
    gImage: Image02,
    alt:"Best Dentist In Dadar",
  },
  {
    id: 3,
    gImage: Image03,
    alt:"Dentists Near Dadar",
  },
  {
    id: 4,
    gImage: Image04,
    alt:"Best Dental Clinic In Dadar West",
  },
  {
    id: 5,
    gImage: Image05,
    alt:"Dental Clinic In Dadar",
  },
  {
    id: 6,
    gImage: Image06,
    alt:"Dental Clinic In Mumbai",
  },
];

export default GalleryData;

