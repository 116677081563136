import logo from './logo.svg';
import './App.css';
import Header from './Component/Navbar/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import ServiceList from './Component/Services/ServiceList';
import ServiceDetail from './Component/Services/ServiceDetail';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import ErrorPage from './Component/Error Page/ErrorPage'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dental-clinic-dadar' element={<About />} />
          {
            ServiceList.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.Slugs}
                element={<ServiceDetail />}
              />
            ))
          }
          <Route path='/dentists-near-dadar' element={<Contact />} />
          <Route path='/best-dentists-dadar-west' element={<Gallery />} />
          <Route path='/*' element={<ErrorPage />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
