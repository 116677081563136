import React from 'react';
import "../Form/HomeForm.css";
import Formimage from "../../Images/Home/Home Form.avif";
import { Button, DatePicker, Form, Input, Select } from 'antd';
import $ from "jquery";
const HomeForm = () => {
    const [form] = Form.useForm();

    const formSubmit = (values) => {
        const date = values.date.format('YYYY-MM-DD');
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#2698cf">32 DENTAL SQUARE</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2698cf"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Contact No:</strong></td><td style="text-align:left">' + values.phone + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Date:</strong></td><td style="text-align:left">' + date + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Service:</strong></td><td style="text-align:left">' + values.service + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at 32 DENTAL SQUARE</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "poojansancheti@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "32 dental square",
            accountLeadSource: "https://32dentalsquare.in/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        form.resetFields(); // Reset form fields after submission
        return false;
    };


    return (
        <>
            <section
                className="appoinment-area"
            >
                <div className="container inner-content">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="img-holder">
                                <img src={Formimage} alt="Best Orthodontist In Dadar West" />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="appoinment">
                                <div className="sec-title">
                                    <h2 className='Head-Style-Service'>Make an Appointment</h2>
                                    <div className="text-box">
                                        <p>
                                            Contact us today for exceptional dental care and personalized service.
                                        </p>
                                    </div>
                                </div>
                                <Form className="appointment-form" layout='vertical' onFinish={formSubmit} form={form}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter your name" }]}>
                                                <Input placeholder="Enter Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: "Please enter your contact number" }]}>
                                                <Input placeholder="Enter Contact Number" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="date" label="Date" rules={[{ required: true, message: "Please choose a date" }]}>
                                                <DatePicker className="w-100" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="service" label="Select Service" rules={[{ required: true, message: "Please select a service" }]}>
                                                <Select placeholder="Select Service" allowClear>
                                                    <Select.Option value="General Dentistry">General Dentistry</Select.Option>
                                                    <Select.Option value="Cosmetic Dentistry">Cosmetic Dentistry</Select.Option>
                                                    <Select.Option value="Dental Implants">Dental Implants</Select.Option>
                                                    <Select.Option value="Orthodontics">Orthodontics</Select.Option>
                                                    <Select.Option value="Pediatric Dentistry">Pediatric Dentistry</Select.Option>
                                                    <Select.Option value="Geriatric Dentistry">Geriatric Dentistry</Select.Option>
                                                    <Select.Option value="Preventive Dentistry">Preventive Dentistry</Select.Option>
                                                    <Select.Option value="Digital Dentistry">Digital Dentistry</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button type="primary" htmlType="submit" className="thm-btn bg-1">
                                                Make an Appointment
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </>
    )
}

export default HomeForm