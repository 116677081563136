import React from 'react';
import "../Footer/Footer.css";
import logo from '../../Images/Logo/32_dental_Logo.png'
import { Link } from 'react-router-dom';
import { MdAddIcCall } from 'react-icons/md';
import { GoMail } from 'react-icons/go';
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrGoogle } from 'react-icons/gr';
import ServiceList from "../Services/ServiceList"


const Footer = () => {
  return (
    <>
      <div className='footer pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 border-end border-bottom'>
              <div className="address_main">
                <div class="footer-logo">
                  <Link to="/">
                    <img src={logo} class="img-responsive" alt="footer_logo" />
                  </Link>
                </div>
                <p>
                  At 32 Dental Square, we're dedicated to providing premier dental care in Dadar west. With our experienced team and personalized approach, your smile is in good hands. Schedule your appointment today!
                </p>
              </div>
            </div>
            <div className='col-md-8 border-bottom'>
              <div className='row'>
                <div className='col-lg-3'>
                  <div className='footer-head'>
                    <h3>Quick Links</h3>
                  </div>
                  <div className='footer-link'>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/dental-clinic-dadar">About</Link></li>
                      <li><Link to="/best-dentists-dadar-west">Gallery</Link></li>
                      <li><Link to="/dentists-near-dadar">Contact</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='footer-head'>
                    <h3>Services</h3>
                  </div>
                  <div className='footer-service'>
                    <ul>
                      {
                        ServiceList.map((elem) => {
                          return (
                            <>
                              <li><Link to={elem.Slugs}>{elem.ServiceName}</Link></li>
                            </>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='footer-head'>
                    <h3>Contact US</h3>
                  </div>
                  <div className='footer-add'>
                    <ul>
                      <li><a href="https://goo.gl/maps/kWwvaWUiQbZ3qMYPA" target='blank'><p>43 ,joshi wadi ,chitale path, Bhavani Shankar Rd, off Bhavani Shankar Junior College, Mumbai, Maharashtra 400028</p></a></li>
                    </ul>
                  </div>
                  <div className="footer-icon">
                    <ul>
                      <li><i><MdAddIcCall /></i><span>Call us : </span> <a href="tel:+919821751757">+91 9821-751-757</a></li>
                      <li><i><GoMail /></i><span>Email :</span><a href="mailto: poojansancheti@gmail.com">poojansancheti@gmail.com</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row p-3'>
            <div className='col-md-6'>
              <div className="copyright-text">
                <p>Copyright &copy; 2024, All Right Reserved <a href="index.html">
                  <strong>32 DENTAL SQUARE</strong></a></p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="footer-menu">
                <div className="copyright-text text-center">
                  <p>Developed by<a href="https://skdm.in/" target='blank'>
                    <strong> Shree Krishna Digital Marketing</strong></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
