import React, { useLayoutEffect, useState } from 'react';
import '../../Css/ServiceDetail.css';
import { Link, useLocation } from 'react-router-dom';
import ServiceList from '../../Component/Services/ServiceList';
import { LiaMapMarkerSolid } from "react-icons/lia";
import { IoMailOpenOutline } from "react-icons/io5";
import { PiPhoneCall } from "react-icons/pi";
import Footer from '../Footer/Footer';
import { MetaTags } from 'react-meta-tags';
const ServiceDetail = () => {


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const search = useLocation();
  const Path = search.pathname;
  const ServiceData = ServiceList.find((e) => e.Slugs === Path);

  const [activeService, setActiveService] = useState(ServiceData ? ServiceData.Slugs : '');


  return (
    <>
      <MetaTags>
        <title>{ServiceData.ServiceTitle}</title>
        <meta title={ServiceData.ServiceTitle}/>
        <meta
          name="description"
          content={ServiceData.ServiceDes}
        />
        <meta
          name="keywords"
          content="Best Dental Clinic In Dadar West,Dental Clinic In Dadar,Dental Clinic In Mumbai,Dentist In Dadar West,Cosmetic Dentistry Doctor In Dadar West,Dental Implant In Dadar West,Best Orthodontist In Dadar West,Pediatric Dentistry In Dadar West,Top Dentistry Dentist in Mumbai,Best Preventive Dentistry In Dadar,Digital Dentistry In Dadar,Best Dentist In Dadar West,Dentists Near Dadar"
        />
        <link rel="canonical" href={ServiceData.Servicecanonical} />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={ServiceData.ServiceTitle} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ServiceData.Servicecanonical} />
        <meta
          property="og:description"
          content={ServiceData.ServiceDes}
        />
        <meta
          property="og:image"
          content="https://32dentalsquare.in/"
        />
      </MetaTags>
      <section className="ServiceDetails-Section-1">
        <div className="container">
          <div className="ServiceDetails-Section-con">
            <div className="ServiceDetails-Section-Text">
              <h1>{ServiceData.h1tag}</h1>
              <div className="ServiceDetails-Section-NavPage">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                  </svg>
                  Home
                </span>
                <h3>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                  </svg>
                </h3>
                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='Service-Detilas-Info-Con'>
        <div className='container'>

          <div className='row'>
            <div className='col-md-4'>
              <div className='Service-Detilas-Services-List-con'>
                <div className='Service-Detilas-Services-List-body'>
                  <div className="single-sidebar">
                    <ul className="all-service">
                      {ServiceList.map((elem) => (
                        <li key={elem.Slugs} className={`${activeService === elem.Slugs ? 'active' : ''}`}>
                          <Link to={elem.Slugs} onClick={() => setActiveService(elem.Slugs)}>{elem.ServiceName}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='Service-Detilas-Services-Time-con'>
                  <h2>Opening Hours</h2>
                  <div className='Service-Details-time-con'>
                    <div className='Service-Detils-Time-Detilas'>
                      <h3>Mon to Saturday:</h3>
                      <h3>10.00 AM to 7.00 PM</h3>
                    </div>
                    <div className='Service-Detils-Time-Detilas'>
                      <h3>Sunday:</h3>
                      <h3>By Appointment Only</h3>
                    </div>
                  </div>
                </div>

                <div className='Service-Detilas-Services-Time-con'>
                  <h2>Quick Contact</h2>
                  <div className='Service-Details-time-con'>
                    <div className='Service-Detils-Time-Detilas-contact'>
                      <h3><LiaMapMarkerSolid /></h3>
                      <p><a href='https://maps.app.goo.gl/7eGSYb8LcLQPfZKz5' target='blank'>43 ,joshi wadi ,chitale path, Bhavani Shankar Rd, off Bhavani Shankar Junior College, Mumbai, Maharashtra 400028</a></p>
                    </div>
                    <div className='Service-Detils-Time-Detilas-contact'>
                      <h3><IoMailOpenOutline /></h3>
                      <p><a href="mailto: poojansancheti@gmail.com">poojansancheti@gmail.com</a></p>
                    </div>
                    <div className='Service-Detils-Time-Detilas-contact'>
                      <h3><PiPhoneCall /></h3>
                      <p><a href="tel:+919821751757">+91 9821-751-757</a></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className='col-md-8'>
              <div className='Service-details-info-con-main'>
                <div className='Service-Details-Heading-con'>
                  <h4 className='Head-Style-Service'>{ServiceData.ServiceName}</h4>
                </div>
                <div className='Service-Details-Heading-para'>
                  <p>{ServiceData.para01}</p>
                  <p>{ServiceData.para02}</p>
                </div>
              </div>
              {
                ServiceData.Procedures && (
                  <div className='Service-deatils-Procedures-details-con'>
                    <div className='Service-deatils-Procedures-details'>
                      {
                        ServiceData.Procedures.map((elem) => {
                          return (
                            <>
                              <div className='Service-Details-Procedures-card-body'>
                                {/* <div className='Service-Details-Procedures-card-body-icon-con'>
                                  <div className='Service-Details-Procedures-card-body-icon'>
                                    <TbDental />
                                  </div>
                                </div> */}
                                <div className='Service-Details-Procedures-card-body-details'>
                                  <h3 className='Head-Style-Service'>{elem.ProceduresHead01}</h3>
                                  <p>{elem.ProceduresPara01}</p>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }

              <div className='Service-details-page-last-para'>
                <p>{ServiceData.lastPara01}</p>
                <p>{ServiceData.lastPara02}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default ServiceDetail;