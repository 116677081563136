import React from 'react';
import "../Team/HomeTeam.css";
import DimpleChandra from "../../Images/Team/Dimple Chandra.avif";
import PoojaMehta from "../../Images/Team/Pooja Mehta.avif";
import NehaJaveri from "../../Images/Team/Neha Javeri.avif";
import khushalgangwani from "../../Images/Team/khushal gangwani.avif";

const HomeTeam = () => {
    return (
        <>
            <section className='about-section-2-con'>
                <div className='container'>
                    <div className='About-Section-2-head'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='About-Section-2-head-title'>
                                    <h2>Team Members</h2>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='About-Section-2-head-title'>
                                    <p>
                                        Meet Our Dedicated Team, The Heart of 32 Dental Square
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-md-3'>
                            <div className='about-section-2-card-con'>
                                <div className='about-section-2-card-body'>
                                    <img src={DimpleChandra} alt='Dr.Dimple Chandra' />
                                    <div className='about-section-2-card-details'>
                                        <h3>Dr.Dimple Chandra</h3>
                                        <p>Oral and maxillofacial surgeon</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='about-section-2-card-con'>
                                <div className='about-section-2-card-body'>
                                    <img src={PoojaMehta} alt='Dr.Pooja Mehta (B.D.S)' />
                                    <div className='about-section-2-card-details'>
                                        <h3>Dr.Pooja Mehta (B.D.S) </h3>
                                        <p>Principal dentist</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='about-section-2-card-con'>
                                <div className='about-section-2-card-body'>
                                    <img src={NehaJaveri} alt='Dr. Neha Javeri' />
                                    <div className='about-section-2-card-details'>
                                        <h3>Dr. Neha Javeri</h3>
                                        <p>MDS Orthodontics and Certified Nutrition and Fitness Coach.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='about-section-2-card-con'>
                                <div className='about-section-2-card-body'>
                                    <img src={khushalgangwani} alt='Dr. khushal gangwani (M.D.S)' />
                                    <div className='about-section-2-card-details'>
                                        <h3>Dr. khushal gangwani (M.D.S)</h3>
                                        <p>Oral and Maxillofacial surgeon and Implantologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTeam