import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// Logo
import Logo from "../../Images/Logo/32_dental_Logo.png";
///////
import "../Navbar/Header.css";
import { Link } from 'react-router-dom';
// social icons 
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import ServiceList from '../Services/ServiceList';
const Header = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <div className='Logo-Con'>
                                <img src={Logo} alt="32 dental clinic" />
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="/dental-clinic-dadar"><Link to="/dental-clinic-dadar">About Us</Link></Nav.Link>
                            <NavDropdown title="Services" id="collapsible-nav-dropdown">
                                {
                                    ServiceList.map((elem) => {
                                        return (
                                            <>
                                                <NavDropdown.Item href={elem.Slugs}><Link to={elem.Slugs}>{elem.ServiceName}</Link></NavDropdown.Item>
                                            </>
                                        )
                                    })
                                }
                            </NavDropdown>
                            <Nav.Link href="/best-dentists-dadar-west"><Link to='/best-dentists-dadar-west'>Gallery</Link></Nav.Link>
                            <Nav.Link href="/dentists-near-dadar"><Link to="/dentists-near-dadar">Contact us</Link></Nav.Link>
                        </Nav>
                        <Nav>
                            <div className='social-media-links-con'>
                                <div className='social-media-links'>
                                    <a href="mailto: poojansancheti@gmail.com">
                                        <IoIosMail />
                                    </a>
                                    <a href="tel:+919821751757">
                                        <IoIosCall />
                                    </a>
                                    <a href="https://maps.app.goo.gl/uUrrbKqKwhjaPXeN9">
                                        <IoLocationSharp />
                                    </a>
                                </div>

                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header