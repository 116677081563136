import React, { useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import "../Css/Home.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import MetaTags from 'react-meta-tags';

// Import your component modules here
import HomeAbout from '../Component/About/HomeAbout';
import Welcome from '../Component/HomeWelcome/Welcome';
import Service from '../Component/HomeService/Service';
import Dentalcare from '../Component/HomeDentalcare/Dentalcare';
import HomeForm from '../Component/Form/HomeForm';
import Testimonial from '../Component/HomeTestimonial/Testimonial';
import Footer from '../Component/Footer/Footer';

import $ from "jquery";

const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    const formSubmit = (values) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#2698cf">32 DENTAL SQUARE</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2698cf"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Email Id:</strong></td><td style="text-align:left">' + values.email + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Service:</strong></td><td style="text-align:left">' + values.service + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at 32 DENTAL SQUARE</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "poojansancheti@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "32 dental square",
            accountLeadSource: "https://32dentalsquare.in/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        setIsModalOpen(false);
        form.resetFields(); // Reset form fields after submission
        return false;
    };

    return (
        <>
            <MetaTags>
                <title>Best Dental Clinic In Dadar West | 32 Dental Square</title>
                <meta name="description" content="32 Dental Square, the best dental clinic in Dadar West, offers comprehensive oral care with advanced treatments and skilled professionals." />
                <link rel="canonical" href="https://32dentalsquare.in/" />
                <meta property="og:title" content="Best Dental Clinic In Dadar West | 32 Dental Square" />
                <meta property="og:url" content="https://32dentalsquare.in/" />
                <meta property="og:description" content="32 Dental Square, the best dental clinic in Dadar West, offers comprehensive oral care with advanced treatments and skilled professionals." />
            </MetaTags>

            <section className='Home-section-Banner-con'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                    pagination={{ clickable: true }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='Home-Banner-Image-con'>
                            <div className='Home-Banner-Text-Con'>
                                <div className='Home-Banner-Text-details'>
                                    <h2>Welcome To 32 Dental Square</h2>
                                    <h1>Best Dental Clinic In Dadar West</h1>
                                    <p>Discover Comprehensive Dental Solutions at 32 Dental Square, Dadar West - Where Excellence Meets Care, Offering Cutting-Edge Treatments and Personalized Service for Your Perfect Smile and Optimal Oral Health.</p>
                                    <div className='Home-Banner-btn-con'>
                                        <a href="tel:+919821751757">
                                            <Button className="custom-btn btn-11">Book A Virtual Consultation<div className="dot"></div></Button>
                                        </a>
                                        <div className='banner-booking-form'  onClick={showModal}>
                                            <button class="custom-btn btn-11">Schedule A Call<div class="dot"></div></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>

            <HomeAbout />
            <Welcome />
            <Service />
            <Dentalcare />
            <HomeForm />
            <Testimonial />
            <Footer />

            <Modal title="Schedule a Call" open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form form={form} layout='vertical' onFinish={formSubmit}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter your name!' }]}
                    >
                        <Input placeholder="Enter your name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Your Email"
                        rules={[{ required: true, message: 'Please enter your email!' }]}
                    >
                        <Input placeholder="Enter your email" />
                    </Form.Item>
                    <Form.Item
                        name="contact"
                        label="Your Phone Number"
                        rules={[{ required: true, message: 'Please enter your contact number!' }]}
                    >
                        <Input placeholder="Enter your contact number" />
                    </Form.Item>
                    <Form.Item
                        name="service"
                        label="Service"
                        rules={[{ required: true, message: 'Please select a service!' }]}
                    >
                        <Select
                            placeholder="Select Service"
                            allowClear
                        >
                            <Select.Option value="General Dentistry">General Dentistry</Select.Option>
                            <Select.Option value="Cosmetic dentistry">Cosmetic dentistry</Select.Option>
                            <Select.Option value="Dental Implants">Dental Implants</Select.Option>
                            <Select.Option value="Orthodontics">Orthodontics</Select.Option>
                            <Select.Option value="Pediatric Dentistry">Pediatric Dentistry</Select.Option>
                            <Select.Option value="Geriatric Dentistry">Geriatric Dentistry</Select.Option>
                            <Select.Option value="Preventive Dentistry">Preventive Dentistry</Select.Option>
                            <Select.Option value="Digital Dentistry">Digital Dentistry</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Home;
