import React from 'react';
import "../About/HomeAbout.css";
import AboutUs from "../../Images/About/About Image.avif";
const HomeAbout = () => {
    return (
        <>
            <section className='home-about-section-con'>
                <div className='container'>
                    <div className='about-section-image-details'>
                        <div className='row'>
                            <div className='col-md-6 p-0'>
                                <div class="about-us-one-left">
                                    <img src={AboutUs} alt="Best Dental Clinic In Dadar West"/>
                                </div>
                            </div>
                            <div className='col-md-6 p-0'>
                                <div className='about-section-page-about-details-con'>
                                    <div className='about-section-page-about-details'>
                                        <div className='about-section-page-about-info'>
                                            <h3>Welcome to 32 Dental Square</h3>
                                            <p>Welcome to our Best Dental Clinic In Dadar West, where we prioritize comprehensive dental care to uphold your overall health and enhance your smile's radiance. Our approach focuses on building lasting relationships centered around your dental well-being. Beyond addressing immediate concerns, our goal is to sustain optimal oral health for the long term. We strive to ensure every visit is enjoyable, fostering a positive experience for each patient.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout