import React from 'react';
import "../Mission/OurVisionMission.css";
import mission from "../../Images/About/mission.avif";
import vision from '../../Images/About/vision.avif';

const OurVisionMission = () => {
    return (
        <>
            <section className='Our-Vision--Mission-con'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='Our-Vision-Image-con'>
                                        <img src={mission} alt='Dental Clinic In Dadar' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='Our-Vision-Details-con'>
                                        <h2>Our Mission</h2>
                                        <p>Our mission is to offer to our patients the finest dental care in mid Missouri & provide exceptional, prompt customer service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='Our-Vision-Image-con'>
                                        <img src={vision} alt='Dental Clinic In Dadar West' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='Our-Vision-Details-con'>
                                        <h2>Our Vision</h2>
                                        <p>It is our vision to exceed our patients’ expectations in every way possible. We will succeed by providing the best quality dental care complemented.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurVisionMission