import React from 'react';
import "../HomeWelcome/Welcome.css";
import GeneralCosmetic from "../../Images/Home/treatment.png";
import DentalSurgeries from '../../Images/Home/dentist-chair.png';
import LaserTreatments from "../../Images/Home/clean.png";

const Welcome = () => {
    return (
        <>
            <section className="welcome-area">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className='Head-Style'>Welcome to Dentalcare</h2>
                    </div>
                    <div className="row">
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={GeneralCosmetic} alt='Best General & Cosmetic Dental Clinic In Dadar West ' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>General Dentistry</h3>
                                    <p>
                                    We offer  comprehensive general dentistry services to ensure the optimal health and hygiene of your teeth regardless of age. Our team is dedicated to customizing dental hygiene plans according to your individual needs and desires, providing diagnosis, treatments, and close monitoring of your dental health status.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={DentalSurgeries} alt='Best Surgeries Dental Clinic In Dadar West' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Dental implants</h3>
                                    <p>
                                        Dental implants are titanium rods that are surgically placed in the jaw bone, acting as the missing tooth root. This will hold a crown or dental bridge to restore missing teeth, or to hold loose dentures in place, called implant-retained dentures. Under suitable conditions, the bone fuses with the implant, known as Osseointegration
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={LaserTreatments} alt='Best Laser Treatments Dental Clinic In Dadar West' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Invisalign</h3>
                                    <p>
                                        Invisalign uses a series of removable aligners to gradually straighten your teeth. These aligners are made from clear plastic material making them virtually invisible.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                    </div>
                </div>
            </section>

        </>
    )
}

export default Welcome